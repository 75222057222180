import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { cardsExample } from "../utils"
import styles from "./singleWinter.module.scss"
import logo from "../../../images/Logo Page.png"
import locationIcon from "../../../images/winter2022/location.png"
import arrowleft from "../../../images/winter2022/arrowleft.svg"
import arrowright from "../../../images/winter2022/arrowright.svg"
import { IsMobileDevice } from "../../../utils"

const SingleWinter = ({ eventos }) => {
  const [data, setData] = useState({})
  const [position, setPosition] = useState(null)
  const [isMobile, setIsMobile] = useState(false)
  const [filteredLocation, setFilteredLocation] = useState([])

  useEffect(() => {
    setIsMobile(IsMobileDevice())

    let { search } = window.location
    let id = search.split("=")[1] || []
    setPosition(id)

    let filtered = eventos?.filter(elem => {
      return elem.id === id
    })
    setData(filtered[0])

  }, [position])

  const nextPage = async () => {
    let { search } = window.location
    let id = search.split("=")[1] || []
    let same = eventos?.findIndex(x => x.id === id)
    setPosition(eventos[same + 1].id)
    navigate(`/winter/event/?id=${eventos[same + 1].id}`)
  }

  return (
    <div className={styles.container}>
      <img src={data.img} className={styles.fondo} />
      <div className={styles.content}>
        <div className={styles.header}>
          <span onClick={() => navigate("/winter")}>
            {" "}
            <img src={arrowleft} /> {isMobile ? "" : "volver"}
          </span>
          <img src={logo} className={styles.logo} />

          {eventos[eventos.length - 1]?.id == data.id  ? (
            <span></span>
          ) : (
            <span onClick={nextPage}>
              {isMobile ? "" : "siguiente "} <img src={arrowright} />{" "}
            </span>
          )}
        </div>
        <h1> {data.eventName}</h1>
        <div className={styles.locationContainer}>
          <img src={locationIcon} />
          <h3> {data.location}</h3>{" "}
        </div>

        <div className={styles.cartel}>
          <h2>{data.date}</h2>{" "}
        </div>
        <p>{data.description}</p>
        <button onClick={() => navigate("/winter/participate")}>
          PARTICIPAR
        </button>
      </div>
    </div>
  )
}

export default SingleWinter
