import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import AgeGate2 from "../../components/agegate"
import { SingleWinter } from "../../components/Winter2022/SingleWinter"
import { useQueryParam } from "use-query-params"

const SingleWinterPage = ({ data, location }) => {
  const [age, setAge] = useState(true)
  const [events, setEvents] = useState([])

  // useEffect(() => {
  //   let local = sessionStorage.getItem("age")
  //   if (local) setAge(false)
  //   const places = data?.allWinter2022?.nodes.filter(elem => {
  //     return elem.visible && elem.location == location.state.lugar
  //   })
  //   setEvents(places)
  // }, [])
  let query = useQueryParam("id")[0]
  useEffect(() => {
    let local = sessionStorage.getItem("age")
    if (local) setAge(false)
    const places = data?.allWinter2022?.nodes.filter(elem => {
      return elem.visible && elem.id == query
    })
    setEvents(places)
  }, [])

  return !age ? <SingleWinter eventos={events} /> : <AgeGate2 setAge={setAge} />
}

export default SingleWinterPage

export const query = graphql`
  query SingleWinter2022Query {
    allWinter2022 {
      nodes {
        date
        description
        eventName
        id
        img
        img_mobile
        index
        location
        visible
      }
    }
  }
`
