export const cualiQuestions = [
  {
    value: "Mucho",
    label: "Mucho",
  },
  {
    value: "Poco",
    label: "Poco",
  },
  {
    value: "No me interesa",
    label: "No me interesa",
  },
]

export const events = [
  {
    value: "Chapelco",
    label: "Chapelco",
  },
  {
    value: "Cerro Catedral",
    label: "Cerro Catedral",
  },
]

export const preferenceQuestions = [
  {
    value: "Meals",
    label: "Cocinando/ Almorzando o cenando solo o acompañado",
  },
  {
    value: "Relax",
    label: "Cuando me quiero relajar/ Viendo TV/ En la plaza/ Usando RRSS/ Tomando sol",
  },
  {
    value: "Social",
    label: "Con amigxs / compañeros socializando en casa o en un bar",
  },
  {
    value: "Party",
    label: "En una fiesta, after, preboliche, etc",
  },
  {
    value: "Living",
    label: "Trasladándome a algún lugar/ Miientras trabajo o hago mandados",
  },
  {
    value: "No",
    label: "No tomo cerveza",
  },
]
